<template>
  <b-navbar-nav>
    <b-nav-form>
      <div class="d-flex">
        <b-form-input
          v-model="search"
          size="sm"
          class="search-input"
          placeholder="Buscar producto..."
        ></b-form-input>
        <b-button size="sm" @click="searchProduct()" variant="info">
          Buscar
        </b-button>
      </div>
    </b-nav-form>
  </b-navbar-nav>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'SearchMenu',

  mixins: [mixins],

  data() {
    return {
      search: '',
    }
  },

  methods: {
    searchProduct() {
      if (this.search != '') {
        this.$router.push({
          name: 'ShopView',
          query: { search: this.search },
        })
      }
    },
  },
}
</script>
