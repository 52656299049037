import WebLayout from '@/components/layouts/WebLayout'

const routes = [
  {
    path: '/perfil',
    name: 'ProfileView',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/user/ProfileView'),
    meta: {
      layout: WebLayout,
      requiresAuth: true,
      menu: 'userMenu',
      listed: true,
      menuName: 'Perfil',
      order: 1,
      activePages: ['ProfileView'],
      title: 'Perfil',
    },
  },
  {
    path: '/confirmar-email',
    name: 'EmailConfirmationView',
    component: () =>
      import(
        /* webpackChunkName: "email-confirmation" */ '@/views/user/EmailConfirmationView'
      ),
    meta: {
      layout: WebLayout,
      requiresAuth: true,
      menu: 'userMenu',
      listed: true,
      menuName: 'Confirmar Email',
      order: 0,
      activePages: ['EmailConfirmationView'],
      title: 'Confirmar Email',
    },
  },
]

export default routes
