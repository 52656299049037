<template>
  <div class="dashboard-footer">
    <b-container>
      <b-row class="mt-4 mb-3">
        <b-col md="12">
          <small>
            © {{ currentYear }} {{ appName }} | Todos los derechos reservados.
          </small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'DashboardFooter',

  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
