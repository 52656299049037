<template>
  <div id="app">
    <div id="wrapper" class="home-page">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content" class="content">
          <TopBar />
          <b-container fluid class="p-0 navigation">
            <WebNav class="container" />
          </b-container>
          <transition name="fade" mode="out-in">
            <router-view :key="$route.path" />
          </transition>
          <WebFooter />
        </div>
      </div>
    </div>
    <WhatsappChat />
  </div>
</template>

<script>
import TopBar from '@/components/layouts/header/TopBar'
import WebNav from '@/components/layouts/navigation/WebNav'
import WebFooter from '@/components/layouts/footer/WebFooter'
import WhatsappChat from '@/components/shared/WhatsappChat'

export default {
  name: 'WebLayout',

  components: { TopBar, WebNav, WebFooter, WhatsappChat },
}
</script>
