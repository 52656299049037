<template>
  <b-navbar-nav>
    <b-nav-text class="d-flex">
      <div class="d-flex cart-button">
        <div
          class="c-button d-flex align-items-center"
          @click="shopShowCart(!showCartModal)"
        >
          <font-awesome-icon icon="shopping-cart" alt="shopping-bag" />
          <div class="qty-cart">
            <span>{{ itemsInCart }}</span>
          </div>
        </div>
        <transition name="slide-fade">
          <div class="modal-cart" v-show="showCartModal">
            <div class="info">
              <div class="close-cart" @click="shopShowCart(!showCartModal)">
                <span>X</span>
              </div>
              <h5>Tus lista</h5>
              <div v-if="noItems" class="text-center">
                <div class="mt-5 mb-2">
                  <p>Tu lista está vacia</p>
                </div>
              </div>
              <div v-else>
                <vuescroll :ops="ops" class="scroll-box mt-4">
                  <div
                    class="product"
                    v-for="(p, index) in cartProducts"
                    :key="index"
                  >
                    <div class="image-product">
                      <router-link
                        :to="{ name: 'ProductInfo', params: { id: p.id } }"
                      >
                        <img
                          :src="productImage(p.image)"
                          class="img-fluid"
                          alt="Product image"
                        />
                      </router-link>
                    </div>
                    <div class="title">
                      <span>{{ p.model }}</span>
                      <br />
                      <small>
                        <b>{{ p.family }}</b>
                      </small>
                      <br />
                      <small @click="shopRemoveItem(index)">Quitar</small>
                    </div>
                    <div class="brand">
                      <span>Marca: {{ p.brand }}</span>
                    </div>
                    <div class="qty">
                      <b-form-spinbutton
                        id="sb-inline-cart"
                        v-model="p.qty"
                        min="1"
                        max="10"
                        inline
                        @change="shopUpdateQty({ id: p.id, qty: p.qty })"
                      ></b-form-spinbutton>
                    </div>
                  </div>
                </vuescroll>
                <div class="pay">
                  <b-button @click="toCart()">
                    Ir al detalle
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </b-nav-text>
  </b-navbar-nav>
</template>

<script>
import { mapGetters } from 'vuex'
import vuescroll from 'vuescroll'
import mixins from '@/mixins/index'

export default {
  name: 'CartButton',

  mixins: [mixins],

  components: { vuescroll },

  data() {
    return {
      ops: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: true,
          background: '#f8f9fc',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          size: '7px',
          disable: false,
        },
      },
    }
  },

  methods: {
    toCart() {
      this.shopShowCart(!this.showCartModal)
      this.$router.push({ name: 'CartView' })
    },
  },

  computed: {
    ...mapGetters('shop', ['showCartModal', 'cartProducts', 'itemsInCart']),

    noItems() {
      return this.itemsInCart == 0
    },
  },
}
</script>
