import { mapMutations, mapActions } from 'vuex'
import * as d3 from 'd3'

export default {
  methods: {
    ...mapMutations({
      shopShowCart: 'shop/SHOW_CART',
      shopUpdateQty: 'shop/UPDATE_QTY',
      shopClearCart: 'shop/CLEAR',
      shopClearGuest: 'shop/CLEAR_GUEST',
    }),
    ...mapActions({
      authLogOut: 'auth/logOut',
      shopAddItem: 'shop/addItem',
      shopRemoveItem: 'shop/removeItem',
      shopSetGuest: 'shop/setGuest',
    }),
    addClass(e) {
      e.target.classList.add('hovered')
    },
    removeClass(e) {
      e.target.classList.remove('hovered')
    },
    showAlert(data, status) {
      this.$swal({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        icon: status,
        title: data,
      })
    },
    activeMenu(views) {
      return views.includes(this.pageName) ? 'active' : ''
    },
    imageTool(img) {
      return require(`@/assets/images/${img}`)
    },
    productImage(img) {
      return `https://acdparts.s3.us-east-2.amazonaws.com/milwaukee/products/${img}.jpg`
    },
    textLength(text, min, max) {
      const length = text.length
      if (length == 0) return null
      return length >= min && length <= max
    },
    ValidateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
      }
      return false
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    },
    orderMenu(a, b) {
      return a.meta.order - b.meta.order
    },
    addToCart(product) {
      // console.log({ product })
      this.shopShowCart(true)
      this.shopAddItem(product)
      document.body.scrollIntoView({ behavior: 'smooth' })
    },
    handleLogOut() {
      this.authLogOut().then(() => {
        this.$router.push('/')
        this.showAlert('Se cerró sesión con éxito', 'success')
      })
    },
    scrollTo(hash) {
      if (this.pageName == 'HomeView') {
        const el = document.querySelector(hash)
        el &&
          el.scrollIntoView({
            behavior: 'smooth',
          })
      } else {
        this.$router.push({ name: 'HomeView', params: { hash: hash } })
      }
    },
    deleteProps(obj, prop) {
      for (const p of prop) {
        p in obj && delete obj[p]
      }
    },
    async getCSVProducts() {
      const data = await d3.csv('/products-milwaukee.csv')
      const products = data.map((data_) => {
        let obj = { qty: 1, attributes: {} }
        Object.entries(data_).forEach(([key, value]) => {
          const k = key.split('/')
          if (k.length > 1) {
            obj.attributes[k[1]] = value
          } else {
            obj[k[0]] = value
          }

          return obj
        })
        return obj
      })
      return products.filter((p) => p.id != '')
    },
  },
  computed: {
    pageName() {
      return this.$route.name
    },
    pageTitle() {
      return this.$route.meta.title
    },
    loremIpsum() {
      return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
  },
}
