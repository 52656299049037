<template>
  <b-navbar
    toggleable="lg"
    variant="faded"
    type="light"
    :class="`nav-mobile px-2 ${cartMenu ? '' : 'justify-content-center'}`"
  >
    <Slide :class="`${cartMenu ? 'slide-web-menu' : ''}`">
      <b-row>
        <b-col md="12">
          <SearchMenu v-if="searchMenu" />
        </b-col>
        <b-col md="12" class="mt-2">
          <WebMenu menuType="webMenu" />
          <div v-if="authMenu">
            <hr />
            <WebMenu v-if="!logged" menuType="authMenu" />
            <UserMenu v-else />
          </div>
        </b-col>
      </b-row>
    </Slide>
    <NavBrand class="d-flex align-items-center" />
    <CartButton v-if="cartMenu" />
  </b-navbar>
</template>

<script>
import NavBrand from '@/components/layouts/navigation/menu/NavBrand'
import WebMenu from '@/components/layouts/navigation/menu/WebMenu'
import SearchMenu from '@/components/layouts/navigation/menu/SearchMenu'
import UserMenu from '@/components/layouts/navigation/menu/UserMenu'
import CartButton from '@/components/shop/CartButton'
import { Slide } from 'vue-burger-menu'
import { mapGetters } from 'vuex'

export default {
  name: 'WebMobileMenu',

  components: {
    NavBrand,
    WebMenu,
    SearchMenu,
    UserMenu,
    CartButton,
    Slide,
  },

  props: {
    searchMenu: { type: Boolean },
    authMenu: { type: Boolean },
    cartMenu: { type: Boolean },
  },

  computed: {
    ...mapGetters('auth', ['logged']),
  },
}
</script>
