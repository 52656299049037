<template>
  <div class="sidebar">
    <!-- Sidebar -->
    <ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
      <!-- Sidebar - Brand -->
      <div
        class="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <div class="sidebar-brand-icon">
          <router-link
            :to="{
              name: 'DashboardView',
            }"
          >
            <img class="img-fluid" src="@/assets/images/brand/logo_icon.png" />
          </router-link>
        </div>
        <div class="sidebar-brand-text mx-3">
          <router-link
            :to="{
              name: 'DashboardView',
            }"
          >
            <img class="img-fluid" src="@/assets/images/brand/logo.png" />
          </router-link>
        </div>
      </div>
      <!-- Divider -->
      <hr class="sidebar-divider my-0" />
      <SideBarMenu :isAdmin="isAdmin" />
      <!-- Divider -->
      <hr class="sidebar-divider" />
      <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle">
          <font-awesome-icon :icon="['fa', 'ellipsis']" />
        </button>
      </div>
    </ul>
  </div>
</template>

<script>
import SideBarMenu from '@/components/layouts/navigation/menu/SideBarMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',

  components: { SideBarMenu },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
  },
}
</script>
