<template>
  <b-navbar-nav>
    <b-nav-item @click="scrollTo('#about-us')">
      Nosotros
    </b-nav-item>
    <b-nav-item @click="scrollTo('#map')">
      Ubicación
    </b-nav-item>
    <b-nav-item @click="scrollTo('#contact')">
      Contacto
    </b-nav-item>
    <b-nav-item
      v-for="(item, index) in menu"
      :key="`${menuType}-${index}`"
      :to="{ name: item.name }"
      :class="activeMenu(item.meta.activePages)"
    >
      {{ item.meta.menuName }}
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'WebMenu',

  mixins: [mixins],

  props: {
    menuType: { type: String },
  },

  // methods: {
  //   scrollTo(hash) {
  //     if (this.pageName == 'HomeView') {
  //       const el = document.querySelector(hash)
  //       el &&
  //         el.scrollIntoView({
  //           behavior: 'smooth',
  //         })
  //     } else {
  //       this.$router.push({ name: 'HomeView', params: { hash: hash } })
  //     }
  //   },
  // },

  computed: {
    menu() {
      const menu = this.$router.options.routes.filter(
        (route) => route.meta.menu == this.menuType && route.meta.listed,
      )
      return menu.sort(this.orderMenu)
    },
  },
}
</script>
