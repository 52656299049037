import LoginLayout from '@/components/layouts/LoginLayout'

const routes = [
  {
    path: '/entrar',
    name: 'LoginView',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/dashboard/LoginView'),
    meta: {
      layout: LoginLayout,
      requiresAuth: false,
      menu: 'authMenu',
      listed: false,
      menuName: 'Entrar',
      order: 1,
      activePages: ['LoginView'],
      title: 'Entrar',
    },
  },
  {
    path: '/registrarse',
    name: 'RegisterView',
    component: () =>
      import(
        /* webpackChunkName: "register" */ '@/views/dashboard/RegisterView'
      ),
    meta: {
      layout: LoginLayout,
      requiresAuth: false,
      menu: 'authMenu',
      listed: false,
      menuName: 'Registrarse',
      order: 2,
      activePages: ['RegisterView'],
      title: 'Registrarse',
    },
  },
]

export default routes
