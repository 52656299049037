import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('currency', function (value) {
  if (!value) {
    return `$ 0.00 MXN`
  }

  return numeral(value).format('($ 0,0.00)') + ' MXN'
})

Vue.filter('cm', function (value) {
  if (!value) {
    return `-- CM`
  }
  return value + ' CM'
})

Vue.filter('localeDate', function (value) {
  let date = new Date(value)
  date.setDate(date.getDate() + 1)
  let fllwDate = date.toLocaleDateString('es', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  return fllwDate
})

Vue.filter('localeDateDigital', function (value) {
  let date = new Date(value)
  date.setDate(date.getDate() + 1)
  let fllwDate = date.toLocaleDateString('es', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  return fllwDate
})

Vue.filter('singular', function (value) {
  const singular = {
    condolencias: 'condolencia',
    esquelas: 'esquela',
  }
  return singular[value]
})

Vue.filter('capitalize', function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('status', function (value) {
  let status = ''
  switch (value) {
    case 'complete':
      status = 'Completo'
      break
    case 'pending':
      status = 'Pendiente'
      break
    case 'error':
      status = 'Error'
      break
    case 'refund':
      status = 'Reembolso'
      break
    default:
      status = '---'
      break
  }
  return status
})

Vue.filter('shipment', function (value) {
  let status = ''
  switch (value) {
    case 'complete':
      status = 'ENTREGADO'
      break
    case 'pending':
      status = 'ENVIO PENDIENTE'
      break
    case 'on_route':
      status = 'EN CAMINO'
      break
    case 'canceled':
      status = 'CANCELADO'
      break
    case 'pending_payment':
      status = 'EN ESPERA DE PAGO'
      break
    default:
      status = '---'
      break
  }
  return status
})

Vue.filter('pieces', function (value) {
  return value > 1 ? `${value} articulos` : `${value} articulo`
})

Vue.filter('percentage', function (value) {
  return `${value}%`
})
