<template>
  <div class="top-bar">
    <b-container>
      <b-row>
        <b-col md="12" class="top-bar-content">
          <div class="email" style="margin-right: 20px;">
            <font-awesome-icon icon="envelope" />
            <a href="mailto:ventasacdparts-milwaukee@acdparts.mx">
              ventasacdparts-milwaukee@acdparts.mx
            </a>
          </div>
          <div class="phone">
            <font-awesome-icon icon="phone" />
            <a href="tel:+528125171362">812-517-1362</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
}
</script>
