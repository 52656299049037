import WebLayout from '@/components/layouts/WebLayout'

const routes = [
  {
    path: '/tienda',
    name: 'ShopView',
    component: () =>
      import(/* webpackChunkName: "shop" */ '@/views/shop/ShopView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: true,
      menuName: 'Productos',
      order: 3,
      activePages: ['ShopView'],
      title: 'Productos',
    },
  },
  {
    path: '/tienda/producto/',
    name: 'ProductView',
    component: () =>
      import(
        /* webpackChunkName: "product-view" */ '@/views/shop/ProductView.vue'
      ),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Producto',
      order: 0,
      activePages: ['ProductView', 'ProductInfo'],
      title: 'Detalle del producto',
    },
    children: [
      {
        path: ':id',
        name: 'ProductInfo',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/components/shop/ProductInfo.vue'
          ),
        meta: {
          layout: WebLayout,
          requiresAuth: false,
          menu: 'webMenu',
          listed: false,
          menuName: 'Producto',
          order: 0,
          activePages: ['ProductView', 'ProductInfo'],
          title: 'Detalle del producto',
        },
      },
    ],
  },
  {
    path: '/carrito',
    name: 'CartView',
    component: () =>
      import(/* webpackChunkName: "cart" */ '@/views/shop/CartView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Carrito',
      order: 0,
      activePages: ['CartView'],
      title: 'Tu lista de productos',
    },
  },
  {
    path: '/identificarse',
    name: 'LoginCheckoutView',
    component: () =>
      import(
        /* webpackChunkName: "login-checkout" */ '@/views/shop/LoginCheckoutView'
      ),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Identificación - Finalizar compra',
      order: 0,
      activePages: ['LoginCheckoutView'],
      title: 'Identificación - Finalizar compra',
    },
  },
  {
    path: '/cotizar',
    name: 'CheckoutView',
    component: () =>
      import(/* webpackChunkName: "checkout" */ '@/views/shop/CheckoutView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Finalizar compra',
      order: 0,
      activePages: ['CheckoutView'],
      title: 'Información para cotización',
    },
  },
  {
    path: '/gracias',
    name: 'ThankYouView',
    component: () =>
      import(
        /* webpackChunkName: "thank-you" */ '@/views/shop/ThankYouView.vue'
      ),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Gracias',
      order: 0,
      activePages: ['ThankYouView'],
      title: 'Gracias por tu compra',
    },
  },
]

export default routes
