import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBox,
  faCommentAlt,
  faCreditCard,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faUserCircle,
  faCheckCircle,
  faCross,
  faCopy,
  faCamera,
  faEllipsis,
  faGauge,
  faEnvelope,
  faPhone,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons'
import {
  faWhatsapp,
  faFacebookF,
  faInstagram,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faWhatsapp,
  faFacebookF,
  faInstagram,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faBox,
  faCommentAlt,
  faCreditCard,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faUserCircle,
  faCheckCircle,
  faCross,
  faCopy,
  faCamera,
  faEllipsis,
  faGauge,
  faEnvelope,
  faPhone,
  faScrewdriverWrench,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
