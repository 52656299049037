<template>
  <div class="sidebar-menu">
    <li
      v-for="(item, index) in menu"
      :key="`${item.meta.menu}-${index}`"
      :class="`nav-item ${activeMenu(item.meta.activePages)}`"
    >
      <router-link class="nav-link" :to="{ name: item.name }">
        <font-awesome-icon
          :icon="['fa', item.meta.icon]"
          class="sidebar-icon"
        />
        <span>{{ item.meta.menuName }}</span>
      </router-link>
    </li>
  </div>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'SideBarMenu',

  mixins: [mixins],

  props: { isAdmin: { type: Boolean } },

  computed: {
    menu() {
      return this.$router.options.routes.filter(
        (route) => route.meta.menu == 'dashboardMenu' && route.meta.listed,
      )
    },
  },
}
</script>
