<template>
  <div id="wrapper" class="login-layout">
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <router-view class="container-fluid" :key="$route.path" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
}
</script>
