<template>
  <div class="subscribe-form menu left-menu">
    <div class="title">
      <p>¡Suscribete!</p>
    </div>
    <div class="list">
      <span>
        Sé el primero en enterarte de todas nuestras novedades.
      </span>
    </div>
    <div class="call-to-action mt-4">
      <b-form @submit.prevent="send()">
        <b-form-input
          id="email_footer"
          v-model="email"
          type="email"
          placeholder="Ingresa tu correo electrónico"
          @keydown.space.prevent
          :maxlength="40"
          required
        ></b-form-input>
        <b-button type="submit" class="btn">
          SUSCRIBIRME
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'SubscribeForm',

  mixins: [mixins],

  data() {
    return {
      email: '',
    }
  },

  methods: {
    send() {
      // this.subscribe({ email: this.email }).then((res) => {
      //   const text =
      //     res.status == 201
      //       ? 'Te has suscrito exitosamente'
      //       : 'Este correo ya se encuentra registrado'
      //   const icon = res.status == 201 ? 'success' : 'warning'
      //   this.showAlert(text, icon)
      //   this.email = ''
      // })
    },
  },
}
</script>
