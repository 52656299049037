import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const options = {
  confirmButtonColor: '#5cb85c',
  cancelButtonColor: '#d9534f',
}

Vue.use(VueSweetalert2, options)
