<template>
  <b-navbar-nav>
    <b-nav-item-dropdown id="profile-nav" right>
      <template #button-content>
        <font-awesome-icon :icon="['fa', 'user-circle']" />
      </template>
      <b-dropdown-item :to="{ name: 'ProfileView' }">
        Mi perfil
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="handleLogOut">
        Cerrar sesión
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'UserMenu',

  mixins: [mixins],
}
</script>
