<template>
  <div class="not-found">
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NotFoundLayout',
}
</script>
