<template>
  <div id="wrapper" class="dashboard-layout">
    <AutoLogout />
    <SideBar />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <DashboardNav />
        <div class="container-fluid h-100 p-0">
          <router-view class="container page h-100 p-3" :key="$route.path" />
        </div>
      </div>
      <DashboardFooter />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import AutoLogout from '@/components/layouts/header/AutoLogout'
import SideBar from '@/components/layouts/navigation/SideBar'
import DashboardNav from '@/components/layouts/navigation/DashboardNav'
import DashboardFooter from '@/components/layouts/footer/DashboardFooter'
import { mapState } from 'vuex'

export default {
  name: 'DashboardLayout',

  components: {
    AutoLogout,
    SideBar,
    DashboardNav,
    DashboardFooter,
  },

  mounted() {
    this.sidebar()
  },

  methods: {
    sidebar() {
      $('#sidebarToggle, #sidebarToggleTop').on('click', function () {
        $('body').toggleClass('sidebar-toggled')
        $('.sidebar').toggleClass('toggled')
      })
    },
  },

  computed: {
    ...mapState('auth', ['user']),
  },
}
</script>
