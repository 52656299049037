import WebLayout from '@/components/layouts/WebLayout'

const routes = [
  {
    path: '/terminos-y-condiciones',
    name: 'TermsView',
    component: () =>
      import(/* webpackChunkName: "terms" */ '../views/web/TermsView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: false,
      menuName: 'Terminos y condiciones',
      order: 0,
      activePages: ['TermsView'],
      title: 'Terminos y condiciones',
    },
  },
  {
    path: '/privacidad',
    name: 'PrivacyView',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../views/web/PrivacyView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: false,
      menuName: 'Aviso de privacidad',
      order: 0,
      activePages: ['PrivacyView'],
      title: 'Aviso de privacidad',
    },
  },
  {
    path: '/preguntas-frecuentes',
    name: 'FaqView',
    component: () =>
      import(/* webpackChunkName: "faq" */ '../views/web/FaqView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: false,
      menuName: 'Preguntas frecuentes',
      order: 0,
      activePages: ['FaqView'],
      title: 'Preguntas frecuentes',
    },
  },
  {
    path: '/renta',
    name: 'RentView',
    component: () =>
      import(/* webpackChunkName: "contacto" */ '../views/web/RentView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: true,
      menuName: 'SERVICIO DE RENTA DE COMPRESORES',
      order: 1,
      activePages: ['RentView'],
      title: 'Renta',
    },
  },
  {
    path: '/venta',
    name: 'SaleView',
    component: () =>
      import(/* webpackChunkName: "contacto" */ '../views/web/SaleView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: true,
      menuName: 'VENTA DE COMPRESORES, SECADORES, Y CABEZALES',
      order: 2,
      activePages: ['SaleView'],
      title: 'Venta',
    },
  },
  {
    path: '/servicios',
    name: 'ServiceView',
    component: () =>
      import(/* webpackChunkName: "contacto" */ '../views/web/ServiceView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'footerMenu',
      listed: true,
      menuName: 'SERVICIOS DE MANTENIMIENTO',
      order: 3,
      activePages: ['ServiceView'],
      title: 'Servicios',
    },
  },
]

export default routes
