import WebLayout from '@/components/layouts/WebLayout'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/web/HomeView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Inicio',
      order: 1,
      activePages: ['HomeView'],
      title: 'Inicio',
    },
  },
  {
    path: '/nosotros',
    name: 'AboutView',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/web/AboutView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Nosotros',
      order: 2,
      activePages: ['AboutView'],
      title: 'Nosotros',
    },
  },
  {
    path: '/contacto',
    name: 'ContactView',
    component: () =>
      import(/* webpackChunkName: "contacto" */ '../views/web/ContactView'),
    meta: {
      layout: WebLayout,
      requiresAuth: false,
      menu: 'webMenu',
      listed: false,
      menuName: 'Contacto',
      order: 4,
      activePages: ['ContactView'],
      title: 'Contacto',
    },
  },
]

export default routes
