<template>
  <b-navbar-brand :to="{ name: 'HomeView' }">
    <img
      src="@/assets/images/brand/logo.svg"
      class="d-inline-block align-top"
      alt="logo"
    />
    <div class="d-block mx-2"></div>
    <img
      src="@/assets/images/brand/milwaukee.png"
      class="d-inline-block align-top pl-4"
      alt="logo"
    />
    <!-- <span class="pl-3">{{ projectName }}</span> -->
  </b-navbar-brand>
</template>

<script>
export default {
  name: 'NavBrand',

  computed: {
    projectName() {
      return process.env.VUE_APP_NAME
    },
  },
}
</script>
