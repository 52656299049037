import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import NotFoundLayout from '@/components/layouts/NotFoundLayout'

import webMenu from '@/router/webMenu'
import userMenu from '@/router/userMenu'
import shopMenu from '@/router/shopMenu'
import sidebarMenu from '@/router/sidebarMenu'
import authMenu from '@/router/authMenu'
import footerMenu from '@/router/footerMenu'

Vue.use(VueRouter)

const routes = [
  ...webMenu,
  ...userMenu,
  ...shopMenu,
  ...sidebarMenu,
  ...authMenu,
  ...footerMenu,
]
routes.push({
  path: '/:pathMatch(.*)*',
  name: 'NotFoundView',
  component: () =>
    import(/* webpackChunkName: "not-found" */ '@/views/NotFoundView'),
  meta: {
    layout: NotFoundLayout,
    requiresAuth: false,
    menu: 'noMenu',
    listed: true,
    menuName: 'Not Found',
    activePages: ['NotFoundView'],
    title: 'No se encontró la ruta',
  },
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.requiresAuth)
  const loggedIn = store.getters['auth/logged']

  if ((to.name === 'LoginView' || to.name === 'RegisterView') && loggedIn)
    return next({ name: 'DashboardView' })
  if (to.name !== 'LoginView' && !loggedIn && authRequired)
    return next({ name: 'LoginView' })

  return next()
})

router.afterEach(() => {
  document.body.scrollIntoView({ behavior: 'smooth' })
})

export default router
