<template>
  <nav class="dashboard-nav navbar navbar-light bg-light">
    <div class="container-fluid">
      <span class="navbar-brand">{{ title }}</span>
      <div>
        <b-button variant="danger" @click="handleLogOut">
          Cerrar Session
        </b-button>
      </div>
    </div>
  </nav>
</template>

<script>
import mixins from '@/mixins/index'

export default {
  name: 'DashboardNav',

  mixins: [mixins],
}
</script>
