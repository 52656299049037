import DashboardLayout from '@/components/layouts/DashboardLayout'

const routes = [
  {
    path: '/panel',
    name: 'DashboardView',
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/views/dashboard/DashboardView'
      ),
    meta: {
      layout: DashboardLayout,
      requiresAuth: true,
      adminMenu: false,
      icon: 'gauge',
      menu: 'sidebarMenu',
      listed: false,
      menuName: 'Panel',
      order: 1,
      activePages: ['DashboardView'],
      title: 'Panel de control',
    },
  },
]

export default routes
